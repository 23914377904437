import { Customers } from 'utils/Interfaces/Providers';
import { axiosInstance } from 'api';
import { actionButtonEndpoints } from 'api/apiEndpoints';
import { CzechUnknownContracts } from 'utils/Interfaces/Actions';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import GFNSearch from '../GFNSearch/GFNSearch';

function AssignGFN<TData>({
  selectedRows,
  onSuccess
}: {
  selectedRows: Array<TData>;
  onSuccess?: () => void;
}) {
  const { errorDialog, successDialog } = useConfirmDialogs();
  const handleAssignGFN = (selectedCustomer?: Customers) => {
    const [selectedContract] = selectedRows;

    const payload = {
      contractNumber: (selectedContract as CzechUnknownContracts)
        .contractNumber,
      customerId: selectedCustomer?.customerId
    };

    axiosInstance
      .post(actionButtonEndpoints.czechUnknownContractsAssign, [payload])
      .then((_response) => {
        successDialog('', 'Record updated successfully');
        onSuccess?.();
      })
      .catch(() => {
        errorDialog('', 'Your request has been failed. Please try again.');
      });
  };
  return (
    <div data-testid="czechUnknownContract-ActionSection">
      <GFNSearch
        onSubmit={handleAssignGFN}
        title="Assign GFN"
        disabled={!selectedRows.length}
      />
    </div>
  );
}

export default AssignGFN;
