import { Flags } from '@sede-x/shell-ds-react-framework';
import T4EIcon from '../../../assets/icons/t4e_icon.png';
import { StyledAvatar } from './style';

const LabelConstants: { [key: string]: string } = {
  unknownContracts: 'Unknown Contracts'
};

const ReportNameConstants: { [key: string]: string } = {
  unknownContracts: 'Unknown Contracts'
};

export const ReportsMenu = [
  {
    children: [
      {
        key: 'czechTollExpiredCards',
        label: 'Card Change',
        reportName: 'Card Expired (customer)',
        multiRowSelection: false
      },
      {
        key: 'czechTollUnknownContracts',
        label: LabelConstants.unknownContracts,
        reportName: 'Czech Unknown Contract',
        multiRowSelection: false
      }
    ],
    key: 'CzechToll',
    label: 'Czech Toll',
    itemIcon: <Flags.Cz />
  },
  {
    children: [
      {
        key: 'evignetteRemove',
        label: 'Remove',
        reportName: 'Remove',
        multiRowSelection: false
      }
    ],
    key: 'eVignette',
    label: 'eVignette',
    itemIcon: <Flags.Lu />
  },
  {
    children: [
      {
        key: 'belgiumObusOnBlackList',
        label: 'Blacklisted OBUs',
        reportName: 'OBUs on blacklist'
      }
    ],
    key: 'Belgium',
    label: 'Satellic',
    itemIcon: <Flags.Be />
  },
  {
    children: [
      {
        key: 'slovakiaExpiredCards',
        label: 'Card Expiry',
        reportName: 'Card Expired (customer)',
        multiRowSelection: false
      },
      {
        key: 'slovakiaUnknownContracts',
        label: LabelConstants.unknownContracts,
        reportName: ReportNameConstants.unknownContracts
      }
    ],
    key: 'Slovakia',
    label: 'SkyToll',
    itemIcon: <Flags.Sk />
  },
  {
    children: [
      {
        key: 't4eTransferObuExceptions',
        label: 'Transfer OBU Exceptions',
        reportName: 'Transfer OBU Exceptions'
      }
    ],
    key: 'T4E',
    label: 'T4E',
    itemIcon: <StyledAvatar size="small" src={T4EIcon} />
  },
  {
    children: [
      {
        key: 'germanyTransactionError',
        label: 'Transaction Error',
        reportName: 'Transaction Error'
      },
      {
        key: 'germanyContractDeregistered',
        label: 'Contract Deregistered',
        reportName: 'Contract Deregistered'
      }
    ],
    key: 'Germany',
    label: 'Toll Collect',
    itemIcon: <Flags.De />
  }

  // {
  //   children: [
  //     {
  //       key: 'activeEvignettes',
  //       label: 'Active Evignettes',
  //       reportName: 'Active eVignettes'
  //     }
  //   ],
  //   key: 'eVignette',
  //   label: 'eVignette',
  //   itemIcon: <Flags.Lu />
  // }
];
