import {
  Button,
  Icons,
  Modal,
  Sizes,
  Alignments,
  TextInput,
  Flexbox,
  RadioButton,
  ModalAction,
  Sentiments
} from '@sede-x/shell-ds-react-framework';
import QueryError from 'components/QueryError';
import Table from 'components/Table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import styled from 'styled-components';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api';
import { Customers } from 'utils/Interfaces/Providers';
import { columnBuilder } from 'utils/helpers';
import { useSdsPagination } from 'hooks/use-pagination';
import ActionColumns from '../../../../../components/Table/columns/actionColumns.json';

const StyledModal = styled(Modal)`
  .shell-modal-container-content {
    height: 80vh;
  }
`;

const columnHelper = createColumnHelper<Customers>();
const columns = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <RadioButton
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          data-testid={`rowcheck-${row.index}`}
          size={Sizes.ExtraSmall}
        />
      </Flexbox>
    ),
    header: ''
  }),
  ...columnBuilder<Customers>(ActionColumns.customerSearchColumns)
];

export const StyledTextInput = styled(TextInput)`
  > div[type='prefix'] {
    font-size: 13px;
    white-space: nowrap;
  }
`;

async function fetchCustomer(pageNumber: number, pageSize: number) {
  const { data } = await axiosInstance.post(
    'report/action/czechunknowncontracts/customers',
    {
      pageNumber,
      pageSize
    }
  );
  return data;
}

const STALE_TIME = 300000;
const DEFAULT_LIMIT = 20;

function GFNSearch({
  title,
  disabled,
  onSubmit
}: {
  title: string;
  disabled?: boolean;
  onSubmit: (value?: Customers) => void;
}) {
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Customers[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_LIMIT);

  const { isError, data, isLoading, isFetching } = useQuery({
    queryKey: ['customers', page, pageSize],
    queryFn: () => fetchCustomer(page, pageSize),
    keepPreviousData: true,
    staleTime: STALE_TIME
  });

  const handleChangePagination = (pageNumber: number, newPageSize: number) => {
    setSelectedRows([]);
    setPage(pageNumber);
    setPageSize(newPageSize);
  };
  const paginationProps = useSdsPagination(handleChangePagination);
  const { resetPagination } = paginationProps;

  const handleOnClose = () => {
    resetPagination();
    setOpen(false);
    setSelectedRows([]);
  };

  const [selectedCustomer] = selectedRows;

  const actions = [
    {
      label: 'Assign GFN',
      action: () => {
        onSubmit(selectedCustomer);
        handleOnClose();
      },
      props: {
        variant: 'filled',
        iconPosition: 'left',
        size: 'small',
        hidden: isError || isLoading,
        disabled: !selectedRows.length,
        'data-testid': `GFN-assign-btn`
      }
    }
  ];
  const { data: customerData, totalNumberOfRecords } = data || {};

  return (
    <div>
      <Button
        size="small"
        onClick={() => {
          setOpen(true);
        }}
        sentiment={Sentiments.Positive}
        icon={<Icons.SwapHorizontal />}
        variant="outlined"
        data-testid="customer-assign-btn"
        aria-label="Customer selector"
        disabled={disabled}
      >
        Assign GFN
      </Button>
      {open && (
        <StyledModal
          width="70%"
          title={title}
          open={open}
          size={Sizes.Small}
          maskClosable={false}
          actions={actions as ModalAction[]}
          mask
          onClose={handleOnClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          bodyPadding={false}
        >
          <div className="mr-2 flex p-3 flex-col flex-grow overflow-auto h-full ">
            <QueryError isError={isError} isLoading={isFetching}>
              <Table<Customers>
                columns={columns}
                data={customerData || []}
                columnSelection={false}
                exportEnabled={false}
                enableMultiRowSelection={false}
                onSelectedRowsChange={setSelectedRows}
                paginationData={{
                  ...paginationProps,
                  total: totalNumberOfRecords
                }}
              />
            </QueryError>
          </div>
        </StyledModal>
      )}
    </div>
  );
}
export default GFNSearch;
