import { Icons, Menu, TextInput } from '@sede-x/shell-ds-react-framework';
import { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { createMap } from 'utils/helpers';
import { ItemType, MenuInfo } from 'rc-menu/lib/interface';
import { MenuProps } from './types';
import { StyledMenuItem, SubMenuItem } from './styles';

const FULL_WIDTH = 284;

const SideMenu = ({ setMenu, menuItems, searchEnabled }: MenuProps) => {
  const [filterdItems, setFilterdItems] = useState<ItemType[] | undefined>(
    menuItems
  );
  const [search, setSearch] = useState<string>('');
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [menuMap, setMenuMap] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    setMenuMap(createMap(menuItems, 'key'));
    setFilterdItems(menuItems);
  }, [menuItems]);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  function searchInMenu(items: ItemType[], value: string) {
    return [...items].filter(function f(o) {
      const item = o as any;
      return (
        item.label.toLowerCase().includes(value.toLowerCase()) ||
        (item.children && (item.children = item.children.filter(f)).length)
      );
    });
  }
  const rootSubmenuKeys = filterdItems?.map((item: any) => item.key);

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (
      latestOpenKey &&
      rootSubmenuKeys &&
      rootSubmenuKeys.indexOf(latestOpenKey) === -1
    ) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [...keys]);
    }
  };

  useEffect(() => {
    setFilterdItems(searchInMenu(cloneDeep<ItemType[]>(menuItems), search));
  }, [search, menuItems]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleChange = (menu: MenuInfo) => {
    setMenu({
      ...menu,
      label: menuMap[menu.key].label,
      exportAll: menuMap[menu.key].exportAll,
      reportName: menuMap[menu.key].reportName,
      multiRowSelection: menuMap[menu.key].multiRowSelection
    });
  };

  const renderMenu = (items?: ItemType[]) =>
    items?.map((item) => {
      const { label, children, key, type, ...restProps } = item as any;
      if (children) {
        // Sub Menu
        return (
          <SubMenuItem key={key} {...restProps} title={label}>
            {renderMenu(children)}
          </SubMenuItem>
        );
      }

      return (
        <StyledMenuItem key={key} {...restProps}>
          {label}
        </StyledMenuItem>
      );
    });

  return (
    <div style={{ width: FULL_WIDTH }}>
      {searchEnabled && (
        <>
          <div className="p-3">
            <TextInput
              suffix={{
                node: <Icons.Search height={24} width={24} />
              }}
              placeholder="Narrow down list"
              value={search}
              onChange={handleSearch}
              data-testid="search-input"
              clearable
              onClear={() => {
                setSearch('');
              }}
            />
          </div>
          <hr />
        </>
      )}
      <Menu
        indicatorPosition="right"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        size="medium"
        triggerSubMenuAction="click"
        onClick={(menu) => handleChange(menu)}
        data-testid="side-menu"
      >
        {renderMenu(filterdItems)}
      </Menu>
    </div>
  );
};

export default SideMenu;
