import {
  Sizes,
  Icons,
  Sentiments,
  AlertAction
} from '@sede-x/shell-ds-react-framework';
import React, { useMemo } from 'react';
import parse from 'html-react-parser';
import useConfirm from 'hooks/useConfirm';

import { StyledAlert, StyledModal } from './ConfirmDialog.styles';

const ConfirmDialog: React.FC = () => {
  const {
    title,
    description,
    isOpen,
    proceed,
    cancel,
    primaryActionLabel,
    secondaryActionLabel,
    type,
    primaryActionIcon
  } = useConfirm();

  const actions: Array<AlertAction> = useMemo(() => {
    const result = [];

    if (secondaryActionLabel) {
      result.push({
        label: secondaryActionLabel,
        action: cancel,
        showActionButton: !!secondaryActionLabel,
        props: {
          'data-testid': 'confirm-dialog-secondary-action'
        }
      });
    }
    if (primaryActionLabel) {
      result.push({
        label: primaryActionLabel,
        action: () => proceed(true),
        showActionButton: !!primaryActionLabel,
        props: {
          icon: primaryActionIcon,
          filled: 'true',
          'data-testid': 'confirm-dialog-primary-action'
        }
      });
    }

    return result;
  }, [
    cancel,
    primaryActionLabel,
    proceed,
    secondaryActionLabel,
    type,
    primaryActionIcon
  ]);

  const getIcon = () => {
    const size = {
      height: '24px',
      width: '24px'
    };
    switch (type) {
      case 'success':
        return <Icons.CheckCircle {...size} fill="#008557" />;
      case 'warning':
        return <Icons.AlertCircle {...size} fill="#B25F00" />;
      case 'error':
        return <Icons.CrossCircle {...size} fill="#DD1D21" />;
      default:
        return <Icons.AlertCircleSolid {...size} fill="#B25F00" />;
    }
  };

  const getSentiment = () => {
    switch (type) {
      case 'success':
        return Sentiments.Positive;
      case 'error':
        return Sentiments.Negative;
      default:
        return Sentiments.Warning;
    }
  };

  return (
    <StyledModal
      open={isOpen}
      onClose={cancel}
      showHeader={false}
      closable={false}
      maskClosable={false}
      bodyPadding={false}
      centered
      mask={false}
      className="!z-50"
    >
      <StyledAlert
        dismissButtonTitle="HideAlert"
        dismissible={type !== 'info'}
        size={Sizes.Medium}
        elevation
        onDismissClick={cancel}
        actions={actions.length ? actions : undefined}
        label={title}
        icon={getIcon()}
        sentiment={getSentiment()}
      >
        <div className="max-h-56 overflow-auto">
          {description && parse(description)}
        </div>
      </StyledAlert>
    </StyledModal>
  );
};

export default ConfirmDialog;
