import {
  Button,
  Icons,
  Modal,
  RadioButton,
  Sizes,
  Text
} from '@sede-x/shell-ds-react-framework';
import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStaticData } from '../../hooks/useStaticData';
import { StyledTextInput } from '../ContentHeader/styles';
import { Country, ValueMap } from './type';

const StyledModal = styled(Modal)`
  .shell-modal-container {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.width};
  }
`;

function CountryPicker({
  value,
  name,
  placeHolder,
  onSubmit,
  label,
  handleBlur,
  invalid
}: {
  value: string;
  name?: string;
  label: string | undefined;
  placeHolder: string | undefined;
  onSubmit: (value: string) => void;
  invalid?: boolean;
  handleBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void;
}) {
  const [openCountry, setOpenCountry] = useState(false);
  const [checked, setChecked] = useState<string>('');
  const [valueMap, setValueMap] = useState<ValueMap>({});

  const [inputLabel, setInputLabel] = useState<string>('');

  const { data } = useStaticData('/country', '/country', true);

  useEffect(() => {
    if (data) {
      const obj: ValueMap = {};
      data.data.forEach((dataObj: { [x: string]: string }) => {
        if (dataObj.countryCode && dataObj.shellCountryCode) {
          obj[dataObj.countryID] = {
            value: dataObj.countryID,
            label: `${dataObj.name} (${dataObj.countryCode})`
          };
        }
      });
      setValueMap(obj);
    }
  }, [data]);

  const onInnerClose = () => {
    setOpenCountry(false);
  };

  useEffect(() => {
    setChecked(valueMap[value]?.value);
    !valueMap[value]?.value && setInputLabel(``);
  }, [value]);

  const countryList = data?.data || [];

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setChecked(e.target.value);
    setInputLabel(`${valueMap[e.target.value].label}`);
  };

  return (
    <>
      <div className="flex flex-col w-full">
        <StyledTextInput
          name={name}
          suffix={{
            node: (
              <Button
                iconOnly
                size="small"
                icon={<Icons.GlobeSolid />}
                variant="transparent"
                onClick={() => setOpenCountry(true)}
                data-testid={`${label}-countryIcon`}
                aria-label="Country Selector"
              />
            )
          }}
          size="small"
          invalid={invalid}
          readOnly
          value={inputLabel}
          placeholder={placeHolder}
          data-testid={`${label}-countryInput`}
          clearable
          onClear={() => {
            onSubmit('');
            setChecked('');
            setInputLabel('');
          }}
          onBlur={handleBlur}
        />
      </div>

      <StyledModal
        title="Country Code"
        open={openCountry}
        size={Sizes.Small}
        maskClosable={false}
        contentScrollable
        width="100%"
        onClose={() => {
          onInnerClose();
          setChecked(valueMap[value]?.value);
          setInputLabel(valueMap[value] ? `${valueMap[value].label}` : '');
        }}
        actions={[
          {
            label: 'Submit',
            action: () => {
              onInnerClose();
              onSubmit(valueMap[checked]?.value);
            },
            props: {
              disabled: !checked
            }
          }
        ]}
      >
        <div className="grid grid-cols-[repeat(5,_minmax(200px,_1fr))] gap-7">
          {countryList.map(
            (obj: Country) =>
              obj.shellCountryCode &&
              obj.countryCode && (
                <div
                  key={obj.name}
                  className="flex gap-3 justify-start items-center w-full"
                >
                  <div>
                    <RadioButton
                      size="small"
                      name={name}
                      value={obj.countryID}
                      onChange={handleOnChange}
                      checked={checked === obj.countryID}
                      data-testid={`${obj.name}-country`}
                    />
                  </div>
                  <div>
                    <Text size="small" className="whitespace-nowrap">
                      {`${obj.name} (${obj.countryCode})`}
                    </Text>
                  </div>
                </div>
              )
          )}
        </div>
      </StyledModal>
    </>
  );
}

export default CountryPicker;
