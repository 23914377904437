import {
  ExpanderButton,
  Flexbox,
  RadioButton,
  SelectionCheckbox,
  Sizes
} from '@sede-x/shell-ds-react-framework';
import { createColumnHelper } from '@tanstack/react-table';
import { columnBuilder } from 'utils/helpers';
import { ProvidersData } from '../../../Pages/Providers/types';
import ProviderColumns from './providersColumns.json';
import ActionColumns from './actionColumns.json';

const columnHelper = createColumnHelper<ProvidersData>();

export function getActionColumn(value: string) {
  return columns[value as keyof typeof columns];
}
export const actions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <SelectionCheckbox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          data-testid={`rowcheck-${row.index}`}
        />
      </Flexbox>
    ),
    header: ({ table }) => (
      <Flexbox gap="12px" alignItems="center">
        <SelectionCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      </Flexbox>
    )
  })
];

const expandAction = [
  columnHelper.display({
    id: 'expandAction',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <ExpanderButton
          expanded={row.getIsExpanded()}
          onClick={() => row.toggleExpanded()}
          data-testid={`expandButton-${row.index}`}
        />
      </Flexbox>
    ),
    header: ''
  })
];

const radioactions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <RadioButton
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          data-testid={`rowcheck-${row.index}`}
          size={Sizes.ExtraSmall}
        />
      </Flexbox>
    ),
    header: ''
  })
];

const belgiumObusOnBlackList = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.belgiumObusOnBlackList)
];

const activeEvignettes = [
  ...expandAction,
  ...columnBuilder<ProvidersData>(ProviderColumns.activeEvignettes)
];

const slovakiaExpiredCards = [
  ...radioactions,
  ...columnBuilder<ProvidersData>(ActionColumns.cardExpired)
];

const slovakiaUnknownContracts = [
  ...radioactions,
  ...columnBuilder<ProvidersData>(ProviderColumns.slovakiaUnknownContracts)
];

const germanyTransactionError = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyTransactionError)
];

const germanyContractDeregistered = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyContractDeregistered)
];

const t4eTransferObuExceptions = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.t4eTransferObuExceptions)
];
const czechTollExpiredCards = [
  ...radioactions,
  ...columnBuilder<ProvidersData>(ProviderColumns.czechTollExpiredCards)
];

const evignetteRemove = [
  ...radioactions,
  ...columnBuilder<ProvidersData>(ProviderColumns.evignetteRemove)
];
const czechTollUnknownContracts = [
  ...radioactions,
  ...columnBuilder<ProvidersData>(ActionColumns.czechTollUnknownContracts)
];

export const columns = {
  belgiumObusOnBlackList,
  activeEvignettes,
  germanyTransactionError,
  germanyContractDeregistered,
  t4eTransferObuExceptions,
  czechTollExpiredCards,
  slovakiaExpiredCards,
  evignetteRemove,
  czechTollUnknownContracts,
  slovakiaUnknownContracts
};
