import {
  Icons,
  Modal,
  RadioButton,
  Sizes,
  Text,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStaticData } from '../../hooks/useStaticData';
import { StyledTextInput } from '../ContentHeader/styles';
import { Toll } from './type';

const Label = ({ text, required }: { text: string; required?: boolean }) => (
  <span className="text-sm text-shellLabel items-center flex ">
    <b data-testid={`${text}-label`}>{text}</b>
    {required && <div className="text-red-500">*</div>}
  </span>
);

const StyledModal = styled(Modal)`
  .shell-modal-container {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

function TollPicker({
  value,
  name,
  placeHolder,
  onSubmit,
  label,
  required
}: {
  value: string;
  name?: string;
  label: string | undefined;
  placeHolder: string | undefined;
  onSubmit: (value: string) => void;
  required?: boolean;
}) {
  const [openToll, setOpenToll] = useState(false);
  const [checked, setChecked] = useState<string>('');

  const { data } = useStaticData('toll', 'toll', true);

  const onInnerClose = () => {
    setOpenToll(false);
    setChecked(value);
  };

  useEffect(() => setChecked(value), [value]);

  const tollList = data?.data || [];

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setChecked(e.target.value);
  };

  const getValue = (inputValue: string) => {
    let tollValue = '';
    if (inputValue) {
      tollList?.forEach((toll: Toll) => {
        if (toll.tollId === inputValue) {
          tollValue = `${toll.name}`;
        }
      });
    }
    return tollValue;
  };
  return (
    <>
      <Label text="Toll" required={required} />
      <div className="flex flex-col">
        <StyledTextInput
          suffix={{
            node: (
              <Icons.Location
                onClick={() => setOpenToll(true)}
                className="cursor-pointer"
                data-testid={`${label}-tollIcon`}
              />
            )
          }}
          size="small"
          readOnly
          value={getValue(value)}
          placeholder={placeHolder}
        />
      </div>

      <StyledModal
        title="Toll"
        open={openToll}
        size={Sizes.Small}
        mask={false}
        onClose={() => {
          onInnerClose();
        }}
        actions={[
          {
            label: 'Clear',
            action: () => {
              onInnerClose();
              setChecked('');
              onSubmit('');
            },
            props: {
              variant: Variants.Outlined
            }
          },
          {
            label: 'Submit',
            action: () => {
              onInnerClose();
              onSubmit(checked);
            }
          }
        ]}
      >
        <div className="grid grid-cols-[repeat(5,_minmax(200px,_1fr))] gap-7">
          {tollList.map(
            (obj: Toll) =>
              obj.tollId && (
                <div
                  key={obj.name}
                  className="flex gap-3 justify-start items-center w-full"
                >
                  <div>
                    <RadioButton
                      checked={checked === obj.tollId}
                      size="small"
                      name={name}
                      value={obj.tollId}
                      onChange={handleOnChange}
                      data-testid={`${obj.name}-toll`}
                    />
                  </div>
                  <div>
                    <Text size="small" className="whitespace-nowrap">
                      {obj.name}
                    </Text>
                  </div>
                </div>
              )
          )}
        </div>
      </StyledModal>
    </>
  );
}

export default TollPicker;
