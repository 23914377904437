import { SingleDatePicker, Toggle } from '@sede-x/shell-ds-react-framework';
import dayjs from 'dayjs';
import { ChangeEvent, KeyboardEvent } from 'react';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import { Label } from 'components/Label/Label';
import ColcoPicker from 'components/ColcoPicker/ColcoPicker';
import { StyledTextInput } from '../ContentHeader/styles';
import CountryPicker from '../CountryPicker/CountryPicker';
import MultiSelectTextInput from '../MultiSelectTextInput/MultiSelectTextInput';
import { FilterData, FilterType } from './types';
import SDSSelect from '../SDSSelect/SDSSelect';
import { ErrorType } from '../ContentHeader/types';
import TollPicker from '../TollPicker/TollPicker';

dayjs.extend(weekday);
dayjs.extend(localeData);

const renderError = (error: string) => {
  if (error) {
    return <div className="text-sm text-shellRed">{error}</div>;
  }
  return <></>;
};

function Filters({
  menu,
  filters,
  filterObject,
  errorData,
  handleChangeField,
  handleBlur,
  touched
}: {
  menu: string;
  filters: FilterData;
  filterObject: FilterType;
  errorData: ErrorType;
  handleChangeField: (name: string, value: string) => void;
  handleBlur?: (
    evt: React.FocusEvent<HTMLInputElement>
  ) => void | React.FocusEvent<HTMLElement>;
  touched?: { [key: string]: boolean };
}) {
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const { name, value, maxLength } = event.target;
    const type = event.target.getAttribute('data-datatype');
    const result = type === 'number' ? value.replace(/\D/g, '') : value;
    const v = maxLength > 0 ? result.slice(0, maxLength) : result;
    handleChangeField(name, v);
  }

  const blockInvalidChar = (event: KeyboardEvent<HTMLInputElement>) => {
    ['e', 'E', '+', '-', '.', 'ArrowDown', 'ArrowUp'].includes(event.key) &&
      event.preventDefault();
  };

  const updateState = (key: string, value: string) => {
    handleChangeField(key, value);
  };

  return (
    <div className="grid gap-6  h-full grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
      {filterObject[menu]?.map((filter) => (
        <div
          key={`${filter.id}`}
          className="flex flex-row w-full gap-3 h-full items-center "
        >
          <span className="w-1/5 lg:w-auto">
            <Label
              text={filter.label!}
              required={filter.validation?.required}
            />
          </span>
          <span className="w-[80%] lg:w-full">
            <div className="w-full ">
              {filter.type === 'TextField' && (
                <>
                  <StyledTextInput
                    value={filters[filter.name!] ? filters[filter.name!] : ''}
                    name={filter.name}
                    onChange={handleChange}
                    size="small"
                    data-testid={`${filter.label}-input`}
                    placeholder={filter.placeHolder}
                    autoComplete="off"
                    data-datatype={filter.inputType}
                    onKeyDown={
                      filter.inputType === 'number'
                        ? blockInvalidChar
                        : undefined
                    }
                    maxLength={filter.validation?.maxlength}
                    invalid={
                      !!errorData[filter.id] && touched && touched[filter.name!]
                    }
                    onBlur={handleBlur}
                  />
                </>
              )}
              {filter.type === 'Select' && (
                <>
                  <div>
                    <SDSSelect
                      name={filter.name}
                      options={filter.options}
                      placeHolder={filter.placeHolder}
                      value={
                        filters[filter.name!] ? filters[filter.name!] : null
                      }
                      onChange={(value) => updateState(filter.name!, value)}
                      onOption={filter.onOption}
                      dataEndPoint={filter.dataEndPoint}
                      dataField={filter.dataField}
                      dataLabelField={filter.dataLabelField}
                      dataIdentifier={filter.dataIdentifier}
                      invalid={
                        !!errorData[filter.id] &&
                        touched &&
                        touched[filter.name!]
                      }
                      handleBlur={handleBlur}
                    />
                  </div>
                </>
              )}

              {filter.type === 'SingleDatePicker' && (
                <>
                  <SingleDatePicker
                    placeholder={filter.placeHolder}
                    elevation
                    size="small"
                    name={filter.name}
                    format="DD/MM/YYYY"
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentElement as HTMLElement
                    }
                    invalid={
                      !!errorData[filter.id] && touched && touched[filter.name!]
                    }
                    data-testid={`${filter.label}-datePicker`}
                    value={
                      filters[filter.name!]
                        ? dayjs(new Date(filters[filter.name!]))
                        : null
                    }
                    onChange={(v) => {
                      updateState(filter.name!, v?.toISOString() as string);
                    }}
                    onBlur={handleBlur}
                  />
                </>
              )}
              {filter.type === 'CountryPicker' && (
                <CountryPicker
                  placeHolder={filter.placeHolder}
                  value={filters[filter.name!] ? filters[filter.name!] : ''}
                  name={filter.name}
                  label={filter.label}
                  invalid={
                    !!errorData[filter.id] && touched && touched[filter.name!]
                  }
                  onSubmit={(v) => updateState(filter.name!, v)}
                  handleBlur={handleBlur}
                />
              )}

              {filter.type === 'ColcoPicker' && (
                <ColcoPicker
                  placeHolder={filter.placeHolder}
                  value={filters[filter.name!] ? filters[filter.name!] : ''}
                  name={filter.name}
                  label={filter.label}
                  resetter={menu}
                  invalid={
                    !!errorData[filter.id] && touched && touched[filter.name!]
                  }
                  onSubmit={(v) => updateState(filter.name!, v)}
                  handleBlur={handleBlur}
                />
              )}

              {filter.type === 'Toggle' && (
                <Toggle
                  checked={!!filters[filter.name!]}
                  name={filter.name}
                  label={filter.label}
                  mirrored
                  size="medium"
                  data-testid={`${filter.label}-toggle`}
                  onChange={(v) =>
                    updateState(filter.name!, v as unknown as string)
                  }
                />
              )}

              {filter.type === 'MultiSelectTextInput' && (
                <MultiSelectTextInput
                  placeHolder={filter.placeHolder}
                  options={filter.options}
                  onChange={(key, value) => {
                    updateState(key, value);
                  }}
                />
              )}

              {filter.type === 'TollPicker' && (
                <TollPicker
                  placeHolder={filter.placeHolder}
                  value={filters[filter.name!] ? filters[filter.name!] : ''}
                  name={filter.name}
                  label={filter.label}
                  onSubmit={(v) => updateState(filter.name!, v)}
                  required={filter.validation?.required}
                />
              )}
              <span className="absolute">
                {touched &&
                  touched[filter.name!] &&
                  renderError(errorData[filter.id])}
              </span>
            </div>
          </span>
        </div>
      ))}
    </div>
  );
}

export default Filters;
