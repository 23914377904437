import { Icons, Pecten } from '@sede-x/shell-ds-react-framework';

export const ReportsMenu = [
  {
    children: [
      {
        key: 'blacklist',
        label: 'Blacklist',
        reportName: 'Blacklist',
        exportAll: true
      },
      {
        key: 'cards',
        label: 'Cards',
        children: [
          {
            key: 'cardExchange',
            label: 'Card exchange',
            reportName: 'Card Exchange',
            exportAll: true
          },
          {
            key: 'cardIncorrectAssigned',
            label: 'Card incorrect assigned',
            reportName: 'Card incorrect assigned',
            exportAll: true
          },
          {
            key: 'cardIssuerIncorrect',
            label: 'Card Issuer Incorrect',
            reportName: 'Card Issuer Incorrect',
            exportAll: true
          }
        ]
      },
      {
        key: 'customers',
        label: 'Customers',
        children: [
          {
            key: 'blockedCustomer',
            label: 'Blocked Customer',
            reportName: 'Blocked Customer',
            exportAll: true
          },
          {
            key: 'customerComments',
            label: 'Comments',
            reportName: 'Comments',
            exportAll: true
          },
          {
            key: 'customerContacts',
            label: 'Contacts',
            reportName: 'Contacts',
            exportAll: true
          },
          {
            key: 'customerStatus',
            label: 'Customer Status',
            reportName: 'Customer status',
            exportAll: true
          },
          {
            key: 'registeredAccountsOverview',
            label: 'Registered Accounts Overview',
            reportName: 'Registered Accounts Overview',
            exportAll: true
          },
          {
            key: 'customerMissingMail',
            label: 'Customer Missing Mail',
            reportName: 'Customer Missing Mail',
            exportAll: true
          }
        ]
      },
      {
        key: 'reports',
        label: 'Reports',
        reportName: 'Reports',
        exportAll: true
      },
      {
        key: 'system',
        label: 'System',
        children: [
          {
            key: 'systemJobs',
            label: 'Jobs',
            reportName: 'Jobs',
            exportAll: true
          }
        ]
      },
      {
        key: 'interfaceRun',
        label: 'Interface Run',
        children: [
          {
            key: 'interfaceRunError',
            label: 'Error',
            reportName: 'Error',
            exportAll: true
          },
          {
            key: 'interfaceRuns',
            label: 'Interface Runs',
            reportName: 'Interface Runs',
            exportAll: true
          }
        ]
      },
      {
        key: 'noVirtualCardActivated',
        label: 'No Virtual Card Activated',
        reportName: 'No Virtual Card Activated',
        exportAll: true
      },
      {
        key: 'users',
        label: 'User',
        children: [
          {
            key: 'userError',
            label: 'User Error',
            reportName: 'User Error',
            exportAll: true
          },
          {
            key: 'userList',
            label: 'User List',
            reportName: 'User List',
            exportAll: true
          }
        ]
      },
      {
        key: 'vehicle',
        label: 'Vehicle',
        children: [
          {
            key: 'duplicatedVehicles',
            label: 'Duplicated Vehicles',
            reportName: 'Duplicated vehicles',
            exportAll: true
          },
          {
            key: 'vehicleWithCardsInfo',
            label: 'Vehicles with Cards information',
            reportName: 'Vehicle with cards information',
            exportAll: true
          }
        ]
      }
    ],
    key: 'eToll Manager',
    label: 'eTM',
    itemIcon: <Icons.Computer />
  },
  {
    key: 'SFH',
    label: 'SFH',
    itemIcon: <Pecten emphasis={false} />,
    children: [
      {
        key: 'SFH',
        label: 'SFH Transactions',
        reportName: 'SFH Transactions',
        exportAll: true
      },
      {
        key: 'DuplicateTollDomainRegistration',
        label: 'Duplicate Toll Domain Registration',
        reportName: 'Duplicate Toll Domain Registration',
        exportAll: true
      },
      {
        key: 'UnprocessedDeviceOrders',
        label: 'Unprocessed Device Orders',
        reportName: 'Unprocessed Device Orders',
        exportAll: true
      },
      {
        key: 'telepassOrderReport',
        label: 'Telepass Order Report',
        reportName: 'Telepass Order Report'
      },
      {
        key: 'axxesOrder',
        label: 'Axxes Order Report',
        reportName: 'Axxes Order Report',
        exportAll: true
      }
    ]
  },
  {
    key: 'GFN',
    label: 'GFN Reports',
    children: [
      {
        key: 'gfnCardBlocked',
        label: 'Card Blocked',
        reportName: 'GFN Card Blocked',
        exportAll: true
      },
      {
        key: 'gfncustomerCardOrder',
        label: 'Customer Card Order',
        reportName: 'Customer Card Order',
        exportAll: true
      },
      {
        key: 'gfncustomerCardOrderError',
        label: 'Customer Card Order Error',
        reportName: 'Customer Card Order Error',
        exportAll: true
      },
      {
        key: 'customerCardOrderPending',
        label: 'Customer Card Order Pending',
        reportName: 'Customer Card Order Pending',
        exportAll: true
      },
      {
        key: 'gfnMissingCardVehicle',
        label: 'Missing Card Vehicle',
        reportName: 'Missing Card Vehicle',
        exportAll: true
      },
      {
        key: 'gfnOpenTransaction',
        label: 'Open Transaction',
        reportName: 'Open Transaction',
        exportAll: true
      },
      {
        key: 'gfntransaction',
        label: 'Transaction',
        reportName: 'Transaction',
        exportAll: true
      },
      {
        key: 'gfnTransactionError',
        label: 'Transaction Error',
        reportName: 'Transaction Error',
        exportAll: true
      },
      {
        key: 'gfnVehicleCardOrder',
        label: 'Vehicle Card Order',
        reportName: 'Vehicle Card Order',
        exportAll: true
      },
      {
        key: 'gfnVehicleCardOrderError',
        label: 'Vehicle Card Order Error',
        reportName: 'Vehicle Card Order Error',
        exportAll: true
      },
      {
        key: 'gfnExpiredCardReports',
        label: 'Expired Card Reports',
        reportName: 'GFN Expired Card Reports',
        exportAll: true
      },
      {
        key: 'gfnVirtualCardOrder',
        label: 'Virtual Card Order',
        reportName: 'GFN Virtual Card Order',
        exportAll: true
      },
      {
        key: 'gfnCardExchangeHistory',
        label: 'Card Exchange History Reports',
        reportName: 'GFN Card Exchange History Reports',
        exportAll: true
      }
    ],
    itemIcon: <Icons.Mobile />
  }
];

export const ShellConsortiumMenu = [
  {
    key: 'ShellConsortium',
    label: 'Shell Consortium',
    itemIcon: <Pecten emphasis={false} />,
    children: [
      {
        key: 'memberReports',
        label: 'Member reports'
      },
      {
        key: 'vehicleReports',
        label: 'Vehicle Reports'
      }
    ]
  }
];
