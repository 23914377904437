import { Button, Flexbox, Icons } from '@sede-x/shell-ds-react-framework';
import { Table } from '@tanstack/react-table';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

function TableExport<TData>({
  table,
  fileName,
  handleClickExportAll,
  exportAllEnabled
}: {
  table: Table<TData>;
  fileName: string;
  handleClickExportAll?: () => void;
  exportAllEnabled?: boolean;
}) {
  async function exportData() {
    const workBook = new Workbook();
    try {
      const workSheet = workBook.addWorksheet('Sheet 1');
      const headerGroup = table?.getHeaderGroups().at(-1);
      if (headerGroup) {
        workSheet.columns = headerGroup?.headers
          .filter(
            (h) =>
              h.column.getIsVisible() &&
              !['actions', 'expandAction'].includes(h.id)
          )
          .map((header) => ({
            header: header.column.columnDef.header as string,
            key: header.id,
            width: 20
          }));
      }

      const rowModal = table.getIsSomeRowsSelected()
        ? table.getSelectedRowModel()
        : table.getFilteredRowModel();
      const selectedRows = rowModal.rows.map((row) => row.id);

      table.getSortedRowModel().rows.forEach((row) => {
        if (selectedRows?.includes(row.id)) {
          const cells = row.getVisibleCells();

          const values = cells
            .filter(
              (c) =>
                !['actions', 'expandAction'].includes(
                  c.column.columnDef.id as string
                )
            )
            .map((cell) => cell.getValue());
          workSheet.addRow(values);
        }
      });

      workSheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
      });

      const buf = await workBook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
    } finally {
      // removing worksheet's instance to create new one
      workBook.removeWorksheet('Sheet 1');
    }
  }

  return (
    <Flexbox flexDirection="row-reverse" gap="10px">
      {exportAllEnabled && (
        <Button
          size="small"
          icon={<Icons.Download />}
          iconPosition="left"
          onClick={handleClickExportAll}
          data-testid="export-all"
          disabled={!table.getCoreRowModel().rows.length}
        >
          Export All
        </Button>
      )}
      <Button
        size="small"
        variant="outlined"
        disabled={
          !(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) ||
          !table.getCoreRowModel().rows.length
        }
        onClick={() => exportData()}
        data-testid="export-selected"
      >
        Export selected
      </Button>
    </Flexbox>
  );
}

export default TableExport;
