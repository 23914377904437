import { Icons, Text } from '@sede-x/shell-ds-react-framework';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import lodash from 'lodash';
import { ItemType } from 'rc-menu/lib/interface';
import { axiosInstance } from 'api';
import {
  DeleteTransactionPayload,
  ExportAllPayload,
  Roles,
  BlockContractDeregisteredPayload,
  AddExceptionPayload,
  RemoveOrdersPayload
} from './types';

const emptyValue = '';
const DECIMAL_ROUNDOFF_TWO = 2;
dayjs.extend(timezone);
dayjs.extend(utc);

export const renderDate = (
  value: string,
  format: string = 'DD/MM/YYYY HH:mm'
) =>
  value ? (
    <Text size="small">{formatAndParseDate(value, format)}</Text>
  ) : (
    <Text size="small">{emptyValue}</Text>
  );

export const formatAndParseDate = (
  date: string | number | Date,
  format: string
) => dayjs(date).format(format);

export const formatGMTtoLocalDate = (
  date: string | number | Date,
  format: string
) => {
  const gmtTimeObj = dayjs.utc(date);
  const localTimeZone = dayjs.tz.guess();
  return gmtTimeObj.tz(localTimeZone).format(format);
};
type TableData = string | number | boolean | Date | null | undefined;
type ColumnHelperType = {
  [k: string]: TableData;
};

type ColumnType = {
  field: string;
  label: string;
  type?: string;
  decimalRoundOff?: number;
  datePattern?: string;
};

const renderToggle = (value: string) => {
  if (value === 'true') {
    return (
      <span className="flex justify-center">
        <Icons.Check data-testid="toggle-check" fill="#343434" />
      </span>
    );
  }
  return (
    <span className="flex justify-center">
      <Icons.CrossCircle fill="#DD1D21" />
    </span>
  );
};

const checkAndRenderDate = (value: unknown, pattern?: string) => {
  if (value && String(value).trim()) {
    return renderDate(value as string, pattern ?? 'DD/MM/YYYY');
  }
  return emptyValue;
};

export const columnBuilder = <TData,>(columnArray: ColumnType[]) => {
  const result: ColumnDef<TData>[] = [];
  const columnHelper = createColumnHelper<ColumnHelperType>();
  [...columnArray].forEach((data) =>
    result.push(
      columnHelper.accessor(data.field, {
        header: data.label,
        cell: (info) => {
          if (data.type === 'date') {
            return checkAndRenderDate(info.getValue(), data.datePattern);
          }
          if (data.type === 'time') {
            return renderDate(info.getValue() as string, 'h:mm A');
          }
          if (data.type === 'decimal') {
            return (
              info.getValue() &&
              Number(info.getValue()).toFixed(
                data.decimalRoundOff ?? DECIMAL_ROUNDOFF_TWO
              )
            );
          }
          if (data.type === 'toggle') {
            const value = String(info.getValue());
            return renderToggle(value);
          }

          return typeof info.getValue() === 'boolean'
            ? String(info.getValue())
            : info.getValue();
        }
      }) as ColumnDef<TData>
    )
  );
  return result;
};

export const createMap = <TData,>(
  data: TData[],
  key: string,
  subField: string = 'children'
) => {
  function getMembers(member: { [subField: string]: TData[] }): unknown {
    if (!member[subField]?.length) {
      return member;
    }
    return [member, lodash.flatMapDeep(member[subField], getMembers)];
  }

  return lodash.keyBy(lodash.flatMapDeep(data, getMembers), key);
};

export function filterReportMenuBasedOnRoles(
  items: ItemType[],
  userRoles?: Roles
) {
  return [...items].filter((menuItem) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const item = menuItem as any;
    return userRoles?.tollPermissions.some((toll) => {
      if (item.key === toll.tollName) {
        item.children &&
          (item.children = [...item.children].filter(function f(o: unknown) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const subItem = o as any;
            return toll.tollReportPermissions.some((report) => {
              subItem.children =
                subItem.children && [...subItem.children].filter(f);
              return (
                subItem.reportName === report.reportName ||
                subItem.children?.length
              );
            });
          }));
        return item.children?.length || item;
      }
      return false;
    });
  });
}

export const getReportDetails = (
  reportName: string,
  tollId: string,
  userRoles: Roles
) =>
  userRoles.tollPermissions
    .find((toll) => toll.tollID === tollId)
    ?.tollReportPermissions.find((report) => report.reportName === reportName);

export function createExportAllRequest(
  endpoint: string,
  payload: ExportAllPayload
) {
  return axiosInstance.post(endpoint, payload);
}

export function deleteTransationErrorRequest(
  endpoint: string,
  payload: DeleteTransactionPayload
) {
  return axiosInstance.post(endpoint, payload);
}

export function blockRequest(
  endpoint: string,
  payload: BlockContractDeregisteredPayload
) {
  return axiosInstance.post(endpoint, payload);
}

export function addException(endpoint: string, payload: AddExceptionPayload) {
  return axiosInstance.post(endpoint, payload);
}

export function removerOrders(
  endpoint: string,
  payload: RemoveOrdersPayload[]
) {
  return axiosInstance.post(endpoint, payload);
}
