import { Button, Checkbox, Icons } from '@sede-x/shell-ds-react-framework';
import { useState, ChangeEvent } from 'react';
import { Label } from 'components/Label/Label';
import { Customers } from 'utils/Interfaces/Providers';
import { addException } from 'utils/helpers';
import { actionButtonEndpoints } from 'api/apiEndpoints';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import CustomerSearch from '../CustomerSearch/CustomerSearch';

interface ExceptionType {
  forCustomerId: string;
  toCustomerAnyChecked: boolean;
  toCustomerId?: string;
}

function AddException({ onSuccess }: { onSuccess?: () => void }) {
  const [exception, setException] = useState<ExceptionType>({
    forCustomerId: '',
    toCustomerAnyChecked: false,
    toCustomerId: ''
  });
  const { errorDialog, successDialog } = useConfirmDialogs();

  const handleSubmitForCustomer = (rowSelection?: Customers) => {
    setException({
      ...exception,
      forCustomerId: rowSelection?.customerId ?? ''
    });
  };

  const handleSubmitToCustomer = (rowSelection?: Customers) => {
    setException({
      ...exception,
      toCustomerId: rowSelection?.customerId ?? ''
    });
  };

  const handleAnychecked = (e: ChangeEvent<HTMLInputElement>) => {
    setException({
      ...exception,
      toCustomerAnyChecked: e.target.checked
    });
  };

  const handleAddException = () => {
    const requestPayload = { ...exception };
    if (requestPayload.toCustomerAnyChecked) {
      delete requestPayload.toCustomerId;
    }
    addException(actionButtonEndpoints.t4eTransferObuExceptions, requestPayload)
      .then((_response) => {
        successDialog('', 'Record updated successfully');
        onSuccess?.();
      })
      .catch(() => {
        errorDialog('', 'Your request has been failed. Please try again.');
      });
  };

  return (
    <div className="flex flex-row justify-end gap-6 h-full">
      <CustomerSearch
        label="For Customer"
        title="Add Exception for customer"
        value={exception.forCustomerId}
        onSubmit={handleSubmitForCustomer}
      />
      <div className="flex flex-row space-x-1 text-sm items-center">
        <Label text="Any" />
        <Checkbox
          size="small"
          checked={exception.toCustomerAnyChecked}
          onChange={handleAnychecked}
          data-testid="any-checkbox"
        />
      </div>

      <CustomerSearch
        label="To Customer"
        title="Add Exception to customer"
        value={exception.toCustomerId}
        onSubmit={handleSubmitToCustomer}
        anychecked={exception.toCustomerAnyChecked}
      />
      <Button
        data-testid="add-exception-Btn"
        disabled={
          !exception.forCustomerId ||
          !(exception.toCustomerAnyChecked || exception.toCustomerId)
        }
        id="addexceptionbtn"
        icon={<Icons.Add />}
        variant="filled"
        size="small"
        onClick={handleAddException}
      >
        Add Exception
      </Button>
    </div>
  );
}

export default AddException;
