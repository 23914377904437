import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import Filters from '../Filter/Filters';
import { FilterType } from '../Filter/types';
import { ErrorType, ParamsType, TouchData, ValidationRef } from './types';

const { Play } = Icons;
interface Props {
  onButtonClick: () => void;
  menu: string;
  filters: ParamsType;
  setFilters: Dispatch<SetStateAction<ParamsType>>;
  filterObject: FilterType;
  title?: string;
  buttonText?: string;
}

const ContentHeader = forwardRef<ValidationRef, Props>(
  (
    {
      onButtonClick,
      menu,
      filters,
      setFilters,
      filterObject,
      title,
      buttonText = 'Execute'
    },
    ref
  ) => {
    const [errorData, setErrorData] = useState<ErrorType>({});
    const [touched, setTouched] = useState<TouchData>({});

    const { errorDialog } = useConfirmDialogs();

    const handleChange = (name: string, value: string) => {
      if (value) {
        setFilters({
          ...filters,
          [name]: value
        });
      } else {
        setFilters((state) => {
          const data = { ...state };
          delete data[name];
          return data;
        });
      }
      // was the field modified
      setTouched({
        ...touched,
        [name]: true
      });
    };

    const handleBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
      const { name, id } = evt.target;

      setTouched({
        ...touched,
        [name || id]: true
      });
    };

    useEffect(() => {
      setTouched({});
      setErrorData({});
    }, [menu]);

    const validate = () => {
      const errors: ErrorType = {};
      const mandatoryMsg = 'Please fill out this mandatory field';
      filterObject[menu]?.forEach((obj) => {
        const filterObj = filters[obj.name as string];
        if (obj.validation?.required && !filterObj) {
          errors[obj.id] = mandatoryMsg;
        }
        if (
          obj.validation?.maxlength &&
          filterObj &&
          filterObj?.length !== obj.validation?.maxlength
        ) {
          errors[
            obj.id
          ] = `Length should be equal to ${obj.validation?.maxlength}`;
        }

        if (
          obj.validation?.minlength &&
          filterObj &&
          filterObj?.length < obj.validation?.minlength
        ) {
          errors[
            obj.id
          ] = `Minimum length should be ${obj.validation?.minlength}`;
        }
      });
      setErrorData(errors);
      return errors;
    };

    useImperativeHandle(ref, () => ({
      validateFilter(): ErrorType {
        return validate();
      }
    }));

    useEffect(() => {
      validate();
    }, [filters]);

    const handleExecute = () => {
      const errors = validate();

      if (!Object.keys(errors).length) {
        onButtonClick();
      } else {
        errorDialog('', 'Please enter correct data.');
      }
    };

    return (
      <>
        <div className=" bg-white p-5">
          {title && (
            <div className="text-sm leading-4 font-bold pb-2">{title}</div>
          )}
          <div className="flex gap-4 items-center">
            <div className="grow">
              <Filters
                menu={menu}
                filters={filters}
                filterObject={filterObject}
                errorData={errorData}
                handleChangeField={handleChange}
                handleBlur={handleBlur}
                touched={touched}
              />
            </div>
            <div>
              <Button
                icon={<Play />}
                iconPosition="right"
                onClick={handleExecute}
                data-testid="executeBtn"
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
);
export default ContentHeader;
