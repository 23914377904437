import { Outlet } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext, IAuthContext } from 'auth';
import { useStaticData } from 'hooks/useStaticData';
import { AxiosError } from 'axios';
import { Alert, Icons, Loading, Sizes } from '@sede-x/shell-ds-react-framework';
import { StyledModal } from 'components/ConfirmDialog/ConfirmDialog.styles';
import { UN_AUTH_STATUS } from 'api';
import Header from '../../components/header/header';

function Home() {
  const { tokenData } = useContext<IAuthContext>(AuthContext);
  const { error, isLoading } = useStaticData(
    'userroles',
    'Auth/userroles',
    !!tokenData?.mail,
    null,
    { retryOnMount: false }
  );

  const renderApp = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center items-center h-screen">
          <Loading type="default" size="medium" data-testid="roles-loader" />
        </div>
      );
    }

    return (
      <>
        <div className="h-screen flex flex-col">
          <Header />
          <Outlet />
        </div>
        {error && (
          <StyledModal
            open
            closable={false}
            onClose={(): null => null}
            bodyPadding={false}
            showHeader={false}
            maskClosable={false}
            centered
            showFooter={false}
          >
            <Alert
              dismissible={false}
              size={Sizes.Medium}
              label={
                (error as AxiosError)?.response?.status === UN_AUTH_STATUS
                  ? 'Access Restricted'
                  : 'Authentication error:'
              }
              icon={<Icons.BlockLeft fill="#DD1D21" />}
            >
              {(error as AxiosError)?.response?.status === UN_AUTH_STATUS
                ? `
              You dont have access to this application. Please contact system
              administrator.`
                : 'There was a problem while authenticating the user. please refresh the page. If the issue persists, contact the administrator.'}
            </Alert>
          </StyledModal>
        )}
      </>
    );
  };

  return <>{renderApp()}</>;
}

export default Home;
