import {
  Button,
  Icons,
  Modal,
  Sizes,
  Alignments,
  TextInput,
  Flexbox,
  RadioButton,
  ModalAction,
  Sentiments
} from '@sede-x/shell-ds-react-framework';
import QueryError from 'components/QueryError';
import Table from 'components/Table/Table';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Label } from 'components/Label/Label';
import styled from 'styled-components';
import { useState, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api';
import { Customers } from 'utils/Interfaces/Providers';
import { columnBuilder, createMap } from 'utils/helpers';
import { useSdsPagination } from 'hooks/use-pagination';
import ActionColumns from '../../../../../components/Table/columns/actionColumns.json';

export interface CustomerPayload {
  pageNumber: number;
  pageSize: number;
  searchTerm: string;
}

const StyledModal = styled(Modal)`
  .shell-modal-container-content {
    height: 80vh;
  }
`;

const columnHelper = createColumnHelper<Customers>();
const customerColumns = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <RadioButton
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          data-testid={`rowcheck-${row.index}`}
          size={Sizes.ExtraSmall}
        />
      </Flexbox>
    ),
    header: ''
  }),
  ...columnBuilder<Customers>(ActionColumns.addExceptionsGetCustomers)
];

export const StyledTextInput = styled(TextInput)`
  > div[type='prefix'] {
    font-size: 13px;
    white-space: nowrap;
  }
`;

interface CustomerMap {
  [key: string]: Customers;
}

function CustomerSearch({
  label,
  title,
  value,
  onSubmit,
  anychecked,
  triggerComponent = 'textInput',
  disabled = false,
  endpoint = 'report/action/t4e/transferobuexceptions/customer',
  columns = customerColumns
}: {
  label?: string;
  title: string;
  value?: string;
  onSubmit: (value?: Customers) => void;
  anychecked?: boolean;
  triggerComponent?: string;
  disabled?: boolean;
  endpoint?: string;
  columns?: ColumnDef<Customers>[];
}) {
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Customers[]>([]);
  const [searchParam, setSearchParam] = useState('');
  const [customersMap, setCustomersMap] = useState<CustomerMap>({});

  const {
    mutate: getCustomer,
    data,
    isLoading,
    isError,
    reset: resetData
  } = useMutation(
    ['getCustomer', searchParam],
    (payload: CustomerPayload) =>
      axiosInstance.post(endpoint, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      }),
    {
      cacheTime: 0,
      retry: false
    }
  );

  const handleChangePagination = (pageNumber: number, newPageSize: number) => {
    getCustomer({
      pageNumber,
      pageSize: newPageSize,
      searchTerm: searchParam
    });
  };
  const paginationProps = useSdsPagination(handleChangePagination);
  const { resetPagination } = paginationProps;

  const getReportsData = () => {
    resetPagination();
    getCustomer({
      pageNumber: 1,
      pageSize: 20,
      searchTerm: searchParam
    });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchParam) {
      getReportsData();
    }
  };

  const handleOnClose = () => {
    resetData();
    setSearchParam('');
    setOpen(false);
    setSelectedRows([]);
  };

  const [selectedCustomer] = selectedRows;

  const actions = [
    {
      label: 'Assign Customer',
      action: () => {
        onSubmit(selectedCustomer);
        handleOnClose();
      },
      props: {
        variant: 'filled',
        iconPosition: 'left',
        size: 'small',
        hidden: isError || isLoading,
        disabled: !selectedRows.length,
        'data-testid': `${label}-assign-btn`
      }
    }
  ];
  const { data: customerData, totalNumberOfRecords } = data?.data || {};

  useEffect(() => {
    if (customerData?.length) {
      setCustomersMap(
        createMap(customerData, 'customerId') as unknown as CustomerMap
      );
    }
  }, [customerData]);

  return (
    <div>
      {triggerComponent === 'textInput' ? (
        <div className="flex flex-row w-full gap-3 h-full items-center ">
          <span className="w-1/5 lg:w-auto">
            <Label text={label ?? ''} />
          </span>
          <StyledTextInput
            readOnly
            value={customersMap[value ?? '']?.name ?? ''}
            suffix={{
              node: (
                <Button
                  iconOnly
                  size="small"
                  icon={<Icons.Search />}
                  variant="transparent"
                  onClick={() => {
                    setOpen(true);
                  }}
                  data-testid={`${label}-search-btn`}
                  aria-label="Customer selector"
                />
              )
            }}
            size="small"
            data-testid={`${label}-value-field`}
            placeholder="Search"
            autoComplete="off"
            disabled={anychecked}
          />
        </div>
      ) : (
        <Button
          size="small"
          onClick={() => {
            setOpen(true);
          }}
          sentiment={Sentiments.Positive}
          icon={<Icons.SwapHorizontal />}
          variant="outlined"
          data-testid="customer-open-btn"
          aria-label="Customer selector"
          disabled={disabled}
        >
          Assign Customer
        </Button>
      )}
      {open && (
        <StyledModal
          width="70%"
          title={title}
          open={open}
          size={Sizes.Small}
          maskClosable={false}
          actions={actions as ModalAction[]}
          mask
          onClose={handleOnClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          bodyPadding={false}
        >
          <div className="mr-2 flex p-3 flex-col flex-grow overflow-auto h-full ">
            <div className="flex flex-col gap-3 pb-3">
              <span className="lg:w-auto">
                <Label text="Search Customer" />
              </span>
              <div className="w-full md:w-[40%] lg:w-[40%] p-1">
                <StyledTextInput
                  value={searchParam}
                  suffix={{
                    node: (
                      <Button
                        iconOnly
                        size="small"
                        icon={<Icons.Search />}
                        variant="transparent"
                        onClick={() => {
                          if (searchParam) {
                            getReportsData();
                          }
                        }}
                        data-testid="search-btn"
                        aria-label="Customer selector"
                      />
                    )
                  }}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setSearchParam(e.target.value)
                  }
                  onKeyDown={handleKeyDown}
                  size="small"
                  data-testid={`${label}-search-text`}
                  placeholder="Search"
                  autoComplete="off"
                />
              </div>
            </div>
            <QueryError isError={isError} isLoading={isLoading}>
              <Table<Customers>
                columns={columns}
                data={customerData || []}
                columnSelection={false}
                exportEnabled={false}
                enableMultiRowSelection={false}
                onSelectedRowsChange={setSelectedRows}
                paginationData={{
                  ...paginationProps,
                  total: totalNumberOfRecords
                }}
              />
            </QueryError>
          </div>
        </StyledModal>
      )}
    </div>
  );
}
export default CustomerSearch;
